<template>
  <footer>
    <div id="company">Copyright © {{currentYear}} <a href="https://lecada.com.pl">Lecada</a></div>
    <div id="author">Developed with passion <a href="https://a3m.pl" target="_blank">A3M</a></div>
  </footer>
</template>

<script>
export default {
  name: 'Stopka-Footer',
  data() {
    return {}
  },
  
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>